import * as client_hooks from '../../../src/hooks.client.js';
import * as universal_hooks from '../../../src/hooks/index.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74')
];

export const server_loads = [2];

export const dictionary = {
		"/(app)": [5,[2],[3]],
		"/(app)/account": [6,[2],[3]],
		"/(app)/activate": [7,[2],[3]],
		"/(app)/hotels": [8,[2],[3]],
		"/(app)/hotels/[hotel]": [9,[2],[3]],
		"/(app)/hotels/[hotel]/allocation": [10,[2],[3]],
		"/(app)/hotels/[hotel]/booking_engine/deals": [11,[2],[3]],
		"/(app)/hotels/[hotel]/booking_engine/deals/[deal]": [12,[2],[3]],
		"/(app)/hotels/[hotel]/booking_engine/preview": [13,[2],[3]],
		"/(app)/hotels/[hotel]/booking_engine/settings": [14,[2],[3]],
		"/(app)/hotels/[hotel]/booking_engine/statistics": [15,[2],[3]],
		"/(app)/hotels/[hotel]/cashbooks": [16,[2],[3]],
		"/(app)/hotels/[hotel]/cashbooks/[cashbook]": [17,[2],[3]],
		"/(app)/hotels/[hotel]/categories": [18,[2],[3]],
		"/(app)/hotels/[hotel]/categories/new": [20,[2],[3]],
		"/(app)/hotels/[hotel]/categories/[category]": [19,[2],[3]],
		"/(app)/hotels/[hotel]/channel_manager": [21,[2],[3]],
		"/(app)/hotels/[hotel]/citytaxes": [22,[2],[3]],
		"/(app)/hotels/[hotel]/closing_dates": [23,[2],[3]],
		"/(app)/hotels/[hotel]/guests": [24,[2],[3]],
		"/(app)/hotels/[hotel]/guests/customer_categories": [26,[2],[3]],
		"/(app)/hotels/[hotel]/guests/customer_categories/[customer_category]": [27,[2],[3]],
		"/(app)/hotels/[hotel]/guests/monthly_report": [28,[2],[3]],
		"/(app)/hotels/[hotel]/guests/new": [29,[2],[3]],
		"/(app)/hotels/[hotel]/guests/stats": [30,[2],[3]],
		"/(app)/hotels/[hotel]/guests/[guest]": [25,[2],[3]],
		"/(app)/hotels/[hotel]/housekeeping": [31,[2],[3]],
		"/(app)/hotels/[hotel]/housekeeping/settings": [32,[2],[3]],
		"/(app)/hotels/[hotel]/invoices": [33,[2],[3]],
		"/(app)/hotels/[hotel]/invoices/[invoice]": [34,[2],[3]],
		"/(app)/hotels/[hotel]/lists": [35,[2],[3]],
		"/(app)/hotels/[hotel]/mailings/sent": [36,[2],[3]],
		"/(app)/hotels/[hotel]/mailings/templates": [37,[2],[3]],
		"/(app)/hotels/[hotel]/mailings/templates/[template]": [38,[2],[3]],
		"/(app)/hotels/[hotel]/payments": [39,[2],[3]],
		"/(app)/hotels/[hotel]/registration_forms/print": [~41,[4]],
		"/(app)/hotels/[hotel]/registration_forms/[registration_form]": [40,[2],[3]],
		"/(app)/hotels/[hotel]/reminders": [42,[2],[3]],
		"/(app)/hotels/[hotel]/reservation_groups": [43,[2],[3]],
		"/(app)/hotels/[hotel]/reservation_groups/[reservation_group]": [44,[2],[3]],
		"/(app)/hotels/[hotel]/reservations": [45,[2],[3]],
		"/(app)/hotels/[hotel]/reservations/settings": [48,[2],[3]],
		"/(app)/hotels/[hotel]/reservations/[reservation]": [46,[2],[3]],
		"/(app)/hotels/[hotel]/reservations/[reservation]/invoices/new": [47,[2],[3]],
		"/(app)/hotels/[hotel]/rooms": [49,[2],[3]],
		"/(app)/hotels/[hotel]/rooms/allocation_list": [50,[2],[3]],
		"/(app)/hotels/[hotel]/rooms/booking_options": [51,[2],[3]],
		"/(app)/hotels/[hotel]/rooms/booking_options/[booking_option]": [52,[2],[3]],
		"/(app)/hotels/[hotel]/rooms/landlords": [53,[2],[3]],
		"/(app)/hotels/[hotel]/rooms/locking_systems": [54,[2],[3]],
		"/(app)/hotels/[hotel]/rooms/locking_systems/messerschmitt": [55,[2],[3]],
		"/(app)/hotels/[hotel]/rooms/locking_systems/nuki": [56,[2],[3]],
		"/(app)/hotels/[hotel]/rooms/prices": [57,[2],[3]],
		"/(app)/hotels/[hotel]/rooms/rates": [~58,[2],[3]],
		"/(app)/hotels/[hotel]/rooms/rates/[rate]": [59,[2],[3]],
		"/(app)/hotels/[hotel]/rooms/stats": [60,[2],[3]],
		"/(app)/hotels/[hotel]/sales": [61,[2],[3]],
		"/(app)/hotels/[hotel]/settings": [62,[2],[3]],
		"/(app)/hotels/[hotel]/statistics": [63,[2],[3]],
		"/(app)/hotels/[hotel]/users": [64,[2],[3]],
		"/(app)/hotels/[hotel]/users/shift_categories": [66,[2],[3]],
		"/(app)/hotels/[hotel]/users/shift_categories/[shift_category]": [67,[2],[3]],
		"/(app)/hotels/[hotel]/users/[user]": [65,[2],[3]],
		"/(app)/hotels/[hotel]/yield_management": [68,[2],[3]],
		"/(app)/login": [~69,[2],[3]],
		"/(app)/logout": [70,[2],[3]],
		"/(app)/settings": [71,[2],[3]],
		"/(app)/signup": [72,[2],[3]],
		"/(app)/users": [73,[2],[3]],
		"/(app)/users/[user]": [74,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.svelte';