import * as Sentry from '@sentry/sveltekit'

Sentry.init({
	dsn: 'https://164d7f514924c3501a2f9a10139bcefe@o4506387806355456.ingest.sentry.io/4506387810091008',
	// dsn: 'https://164d7f514924c3501a2f9a10139bcefe@debug.casona.com/4506387810091008',

	// We recommend adjusting this value in production, or using tracesSampler
	// for finer control
	tracesSampleRate: 1,
	enableTracing: process.env.NODE_ENV === 'production',

	// Optional: Initialize Session Replay:
	integrations: [new Sentry.Replay()],
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0
})

const myErrorHandler = ({ error, event }) => {
	console.error('An error occurred on the client side:', error, event)
}

// export const handleError = Sentry.handleErrorWithSentry(myErrorHandler)

// or alternatively, if you don't have a custom error handler:
// export const handleError = handleErrorWithSentry();
